import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Icon from "../components/icon.tsx";
import BlogContent from "../components/blog-content";
import Comments from "../components/comments";
import CommentsForm from "../components/comments-form";
import Thumbnail from "../components/thumbnail";
import ProductsToc from "../components/products-toc";
import AdvDis from "../components/adv-dis";
import useDescription from "../hooks/useDescription";
import Breadcrumbs from "../components/breadcrumbs";
import Out from "../components/out-product";
import useMedia from "../hooks/useMedia";
import Translate from "../components/translate";
import scrollTo from "gatsby-plugin-smoothscroll";
import { SCROLL_CLASS, DIRECTION_DOWN_CLASS, DIRECTION_UP_CLASS, } from "../utils/classes";
import resolveUrl from "../utils/urls/resolve-url";
import resolveProductUrl from "../utils/urls/resolve-product-url";
import Layout from "../components/layout";
import SimilarProducts from "../components/similar-products";
import Faq from "../components/faq";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";

export const Head = ({ ...props }) => (
  <BaseHead {...props}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/card/${props.pageContext.mysqlId}.png`} />}
  </BaseHead>
);

const Card = ({
  data: { mysqlCard, faq, allMysqlComments, allMysqlCard, allMysqlBlog, allMysqlDescriptionCard, mysqlRankingProductPositions },
  pageContext: { breadcrumbs },
  location: { pathname },
}) => {
  const {
    disadvantages_description,
    name,
    mysqlImage,
    advantages_description,
    rating,
    card_fee,
    card_fee_info,
    card_operator,
    interest,
    free_period,
    free_period_info,
    atms_cost,
    atms_cost_info,
    mysqlId,
    bank,
    inactive_reason,
  } = mysqlCard;

  const [isOpenToc, setIsOpenToc] = useState(false);

  let idTimeout = null;
  const scroll = (selector) => {
    clearTimeout(idTimeout);

    document.body.classList.remove(DIRECTION_UP_CLASS);
    document.body.classList.add(SCROLL_CLASS, DIRECTION_DOWN_CLASS);
    scrollTo(selector);

    idTimeout = setTimeout(
      () => document.body.classList.remove(SCROLL_CLASS),
      2000
    );
  };

  const d = useDescription(allMysqlDescriptionCard.nodes);
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
      <div className={["card-header", 'intro-products2', b.dFlex, b.justifyContentCenter,].join(" ")}>
      </div>
      <div className={[b.container, 'breadcrumbs-mobile-2', b.py3, b.ptLg5, "scroll-inner"].join(' ')}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <article className={"firm-page"}>
        <div className={["local-firm-page", "blog-content", "post-content"].join(" ")}>
          <div className={b.container}>
            <div className={b.row}>
              <div className={b.colLg8}>
                <div className={[b.dFlex, b.mt3, b.alignItemsCenter].join(" ")}>
                  <div className={["icon-box-acc"].join(" ")}>
                    <Icon size={40} icon="Credit-cards" />
                  </div>
                  <div className={b.colSm10}>
                    <h1 className={["big-title", b.pt2].join(" ")}>{name}</h1>
                  </div>
                </div>
              </div>
              <div className={b.colLg8}>
                {mysqlImage && (
                  <div className={["bank-box-mobile", "margin-mobile"].join(" ")}>
                    {inactive_reason && <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb1].join(' ')}>
                      <Icon size={16} color="#B93838" icon="Info" className={['iconToWhite', b.mr1].join(' ')} />
                      <p className={['redToWhite', 'font-size-13',b.my0,'tp-name'].join(' ')} >{inactive_reason}</p>
                    </div>}

                    <div className={["bank-padding-box", inactive_reason ? b.pt3 : ''].join(' ')}>
                      <div className='bank-padding-img' style={{ width: 'fit-content', margin: '0 auto' }}>
                        <Thumbnail image={mysqlImage} alt={name} />
                      </div>
                      <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3,].join(" ")} onClick={() => scroll('#addComment')} role="button" tabIndex={0}>
                        <div className="stars-container">
                          {rating > 0 && (
                            <div className="star-box" style={{ width: ` ${rating}% ` }}>
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" className={b.mr1} />
                              <Icon size={20} icon="Star" />
                            </div>
                          )}
                          <div className='empty-star-box'>
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" className={b.mr1} />
                            <Icon size={20} icon="Empty-star" />
                          </div>
                        </div>
                      </div>
                      <span className={[b.dFlex, b.justifyContentCenter, "toc-title", "blackToWhite", 'font-size-20'].join(" ")}>
                        {rating > 0 ? (rating / 20).toFixed(1) : 0}
                      </span>
                    </div>
                    <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm();toggleFaq(false); }}>
                      <Icon size={20} icon="Plus-sign" className={[b.mr1, "icon-blog"].join(" ")} />
                      <Translate tKey="Add opinion" />
                    </button>
                    <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(" ")} item={mysqlCard} defaultDescription="Apply for card" defaultIcon="Taking-money" />
                    <div className={[b.dFlex, b.justifyContentCenter, "blueToWhite",].join(" ")}>
                      <div className={[b.w100].join(' ')}>
                        <button className={["btn-hidden", "blueToWhite", 'iconToWhite', b.mt1, "margin-1", b.w100].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                          {!isOpenToc ? (
                            <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} />
                          ) : ("")}
                          {!isOpenToc ? <Translate tKey="Toc" /> : ""}
                        </button>
                        {isOpenToc ? (
                          <ProductsToc selector=".firm-page .blog-content h2[id]" />
                        ) : ("")}

                        <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                          <button className={[b.mt2, "btn-hidden", "blueToWhite", 'iconToWhite',].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                            {!isOpenToc ? ("") : (
                              <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} />
                            )}
                            {!isOpenToc ? "" : <Translate tKey="Collapse the toc" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className={["bank-box", b.mt5].join(" ")}>
                  <div className={b.row}>
                    <div className={[b.colMd7, b.mb3].join(" ")}>
                      <span className='font-size-24'>{name}</span>
                    </div>
                    <div className={[b.colMd5, b.dFlex, b.justifyContentEnd, b.mb3, "display-btn",].join(" ")}>
                      <Out className={[b.btn, "btn-success", "margin-top1", "button-256", b.py2, b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(" ")} item={mysqlCard} defaultDescription="Go to the bank" defaultIcon="Taking-money" />
                    </div>

                    <div className={b.colMd4}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Credit-cards" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Card" /></small>
                          <div className={[b.dFlex, 'firm-box-text'].join(' ')}>
                            {card_fee ? <>{card_fee} <Translate tKey="cu" /></> : '-'}
                            {card_fee_info && (
                              <>
                                <p className="icon-hover">
                                  <Icon size={14} icon="Info" className={[b.ml2, "icon-blog"].join(" ")} />
                                  <span className={"icon-hover-txt"}>
                                    {card_fee_info}
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={b.colMd4}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Atm" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="ATMs" /></small>
                          <div className={[b.dFlex, 'firm-box-text'].join(' ')}>
                            {atms_cost ? <>{atms_cost} <Translate tKey="cu" /></> : '-'}
                            {atms_cost_info && (
                              <>
                                <p className="icon-hover">
                                  <Icon size={14} icon="Info" className={[b.ml2, "icon-blog"].join(" ")} />
                                  <span className={"icon-hover-txt"}>
                                    {atms_cost_info}
                                  </span>
                                </p>
                              </>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={b.colMd4}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Interest" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Interest" /></small>
                          <p className='firm-box-text'>{interest >= 0 ? `${interest} %` : '-'}</p>
                        </div>
                      </div>
                    </div>

                    <div className={b.colMd4}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Free-loan" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Free period" /></small>
                          <div className={[b.dFlex, 'firm-box-text'].join(' ')}>
                            {free_period ? <>{free_period} <Translate tKey="days" /></> : '-'}
                            {free_period_info && (
                              <>
                                <p className="icon-hover">
                                  <Icon size={14} icon="Info" className={[b.ml2, "icon-blog"].join(" ")} />
                                  <span className={"icon-hover-txt"}>
                                    {free_period_info}
                                  </span>
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={b.colMd4}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Calculator-rrso" className={[b.mr2, "icon-blog"].join(" ")} />
                        <div className={b.justifyContentStart}>
                          <small><Translate tKey="Card operator" /></small>
                          <p className='firm-box-text'>{card_operator || '-'}</p>
                        </div>
                      </div>
                    </div>
                    {mysqlRankingProductPositions &&
                      <div className={b.colMd4}>
                        <div className={b.dFlex}>
                          <Icon size={40} icon="Ranking" className={[b.mr2, "icon-blog"].join(" ")} />
                          <div className={b.justifyContentStart}>
                            <small><Translate tKey="Credit Card Ranking" /></small>
                            <div className={[b.dFlex, 'firm-box-text'].join(' ')}>
                              Miejsce {mysqlRankingProductPositions.position}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {allMysqlBlog.nodes.length !== 0 ?
                      <div className={[b.colMd12, b.mt3].join(' ')}>
                        <div className={['account-promotions-btn', b.py1, b.px2, b.dFlex, b.justifyContentBetween, b.alignItemsCenter, 'cursor-pointer'].join(' ')} onClick={() => scroll('#section-title-5')}>
                          <div>
                            <Icon size={40} icon="Coins" className={[b.mr2, "icon-blog"].join(" ")} />
                            Aktualne promocje {name}
                          </div>
                          {!isMobileResolution ?
                            <div>
                              Sprawdź
                              <Icon size={20} icon="Angle-right" className={[b.ml1, "icon-blog"].join(" ")} />
                            </div>
                            : ''
                          }
                        </div>
                      </div>
                      : ''}
                    <div className={[b.colMd5, b.justifyContentEnd, "display-mobile-btn",].join(" ")}>
                      <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(" ")} item={mysqlCard} defaultDescription="Apply for card" defaultIcon="Taking-money" />
                    </div>
                  </div>
                </div>
                <div className={["blueToGray", 'dark-li'].join(' ')}>
                  {d.offer && d.offer.description && (<>
                    <h2 id="section-title-0">
                      {d.offer.header}
                    </h2>
                    <BlogContent content={d.offer.description} />
                  </>)}
                  {d.types && d.types.description && (<>
                    <h2 id="section-title-1">
                      {d.types.header}
                    </h2>
                    <BlogContent content={d.types.description} />
                  </>)}
                  {d.register && d.register.description && (<>
                    <h2 id="section-title-2">
                      {d.register.header}
                    </h2>
                    <BlogContent content={d.register.description} />
                  </>)}
                  {d.requirement && d.requirement.description && (<>
                    <h2 id="section-title-3">
                      {d.requirement.header}
                    </h2>
                    <BlogContent content={d.requirement.description} />
                  </>)}
                  {d.cost && d.cost.description && (<>
                    <h2 id="section-title-4">
                      {d.cost.header}
                    </h2>
                    <BlogContent content={d.cost.description} />
                  </>)}

                  {allMysqlBlog.nodes.length !== 0 && <h2 id="section-title-5">Promocje {name}</h2>}

                  {
                    allMysqlBlog.nodes.map(({ title, description, mysqlImages, image_description, terms_path, term_normalized, url }, i) => (
                      <div key={i} className={['product-promotions', b.p0, b.pLg3, b.mt3].join(' ')}>
                        <div className={b.row}>
                          <div className={[b.colLg4, b.orderLgLast].join(' ')}>
                            <div className={["bank-promotions", b.dFlex, b.justifyContentBetween, b.alignItemsCenter, b.p3].join(' ')}>
                              <div className={[b.w50, b.mr2].join(' ')}>
                                <small className={b.textWhite}>Bonus</small>
                                <div className={[b.pt0, 'font-size-20'].join(' ')}>{image_description}</div>
                              </div>
                              <div className={['bank-list-products-box', b.p3, b.w50].join(' ')}>
                                <Thumbnail image={mysqlImages[0]} alt={title} />
                              </div>
                            </div>
                          </div>
                          <div className={b.colLg8}>
                            <div className={[b.p3, b.pLg0].join(' ')}>
                              <Link to={resolveUrl(terms_path, term_normalized, url)}>
                                <span className={[b.mt0, 'font-size-18', b.fontWeightNormal].join(' ')}>{title}</span>
                              </Link>
                              <p className="font-size-13">{description}</p>
                            </div>
                          </div>
                        </div>
                        <div className={[b.px3, b.pxLg0].join(' ')}>
                          <Link to={resolveUrl(terms_path, term_normalized, url)} className={[b.btn, 'btn-orange', 'btn-normal', 'button-288', b.mxLg0, b.mb3, b.mbLg0, b.mxAuto,].join(' ')}>
                            <Icon size={20} icon="Login" color="#FFFFFF" className={[b.mr1, "icon-blog"].join(" ")} />
                            
                            <span className={b.textWhite}>Skorzystaj z promocji</span>
                          </Link>
                        </div>
                      </div>
                    ))
                  }
                  {d.summary && d.summary.description && (
                    <AdvDis advantages={advantages_description} disadvantages={disadvantages_description}>
                      <h2 id="section-title-6">{d.summary.header}</h2>
                      <BlogContent content={d.summary.description} />
                    </AdvDis>
                  )}
                  {faq?.nodes.length > 0 && (
                    <>
                      <h2 id="section-title-7">
                        Często zadawane pytania
                      </h2>
                      {faq.nodes.map(({ title, text }, i) => (
                        <Faq title={title} text={text} key={i}/>
                      ))}
                      <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                        <button className={[b.btn, "btn-light", "btn-cta", "button-256",].join(" ")} onClick={() => { toggleForm();toggleFaq(true); }}>
                          <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")} />
                          <Translate tKey="Ask your own question" />
                        </button>
                      </div>
                    </>
                  )}
                  <Comments items={allMysqlComments.nodes} title="standard" itemName={bank?.name || ""} formID={mysqlId} commentType="card" />
                  <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="card" faq={isFaq} />
                </div>
              </div>

              {/* DRUGA POŁOWA STRONY                          DRUGA POŁOWA STRONY */}
              <div className={[b.colLg4,b.mbLg5,b.pbLg5].join(' ')}>
                {mysqlImage && (
                  <>
                    <div className={["bank-box-desktop", "padding-bottom", "sticky-box", 'card-margin-top'].join(" ")}>
                      {inactive_reason && <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter,b.mb1].join(' ')}>
                        <Icon size={16} color="#B93838" icon="Info" className={['iconToWhite', b.mr1].join(' ')} />
                        <p className={['redToWhite', 'font-size-13',b.my0,'tp-name'].join(' ')} >{inactive_reason}</p>
                      </div>}

                      <div className={["bank-padding-box", inactive_reason ? b.pt3 : ''].join(' ')}>
                        <div className='bank-padding-img'>
                          <Thumbnail image={mysqlImage} alt={name} />
                        </div>
                        <div className={[b.dFlex, b.pb2, b.justifyContentCenter, b.mt3,].join(" ")} onClick={() => scroll('#addComment')} role="button" tabIndex={0}>
                          <div className="stars-container">
                            {rating > 0 && (
                              <div className="star-box" style={{ width: ` ${rating}% ` }}>
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" className={b.mr1} />
                                <Icon size={20} icon="Star" />
                              </div>
                            )}
                            <div className='empty-star-box'>
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" className={b.mr1} />
                              <Icon size={20} icon="Empty-star" />
                            </div>
                          </div>
                        </div>
                        <span className={[b.dFlex, b.justifyContentCenter, "toc-title", "blackToWhite", 'font-size-20'].join(" ")}>
                          {(rating > 0 ? rating / 20 : rating).toFixed(1)}
                        </span>
                      </div>
                      <button className={[b.btn, "btn-light", b.mt2].join(" ")} onClick={() => { toggleForm();toggleFaq(false); }}>
                        <Icon size={20} icon="Plus-sign" className={[b.mr1, "icon-blog"].join(" ")} />
                        <Translate tKey="Add opinion" />
                      </button>
                      <Out className={[b.btn, "btn-success", "btn-cta", b.mt2, b.fontWeightNormal, b.textWhite, b.justifyContentCenter, b.alignItemsCenter, b.dFlex].join(" ")}
                        item={mysqlCard} defaultDescription="Go to the bank" defaultIcon="Taking-money" />
                      <div className={[b.dFlex, b.justifyContentCenter, "blue-font",].join(" ")}>
                        <div>
                          <button className={["btn-hidden", "blueToWhite", b.mt1, "margin-1", 'iconToWhite'].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                            {!isOpenToc ? (
                              <Icon size={25} icon="Angle-down" className={[b.mr1, "icon-blog"].join(" ")} />
                            ) : ("")}
                            {!isOpenToc ? <Translate tKey="Toc" /> : ""}
                          </button>
                          {isOpenToc ? (
                            <ProductsToc selector=".firm-page .blog-content h2[id]" />
                          ) : ("")}

                          <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                            <button className={[b.mt2, "btn-hidden", "blueToWhite", 'iconToWhite'].join(" ")} onClick={() => setIsOpenToc(!isOpenToc)}>
                              {!isOpenToc ? ("") : (
                                <Icon size={25} icon="Angle-up" className={[b.mr1, "icon-blog"].join(" ")} />
                              )}
                              {!isOpenToc ? "" : <Translate tKey="Collapse the toc" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {bank && bank.name && (
                  <div className={"local-firm-box-1"}>
                    <div className={['font-size-24', b.mb3].join(' ')}><Translate tKey="Products" /> {bank.name}</div>
                    {bank.credits.length > 0 && (
                      bank.credits.map(({ type, name, ...credit }, i) => (
                        <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                          <Link to={resolveProductUrl({ bank, ...credit })}>
                            <div className={[b.dFlex, b.mr3].join(" ")}>
                              {type === 2 ? <Icon size={40} icon="Cash-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 1 ? <Icon size={40} icon="Mortgage" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 4 ? <Icon size={40} icon="Consolidation-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 3 ? <Icon size={40} icon="Car-loan" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 5 ? <Icon size={40} icon="Deposits" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                            </div>
                          </Link>
                          <Out item={credit} withoutText />
                        </div>
                      ))
                    )}
                    <div className={[b.dFlex, b.justifyContentBetween].join(' ')}>
                      <div className={b.dFlex}>
                        <Icon size={40} icon="Credit-cards" className={[b.mr2, "icon-blog"].join(" ")} />
                        <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>
                          {name}
                        </p>
                      </div>
                      <Out item={mysqlCard} withoutText />
                    </div>
                    {bank.accounts.length > 0 && (
                      bank.accounts.map(({ type, name, ...account }, i) => (
                        <div className={[b.dFlex, b.justifyContentBetween].join(' ')} key={i}>
                          <Link to={resolveProductUrl({ bank, ...account })}>
                            <div className={[b.dFlex, b.mr3].join(' ')}>
                              {type === 1 ? <Icon size={40} icon="Bank-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 2 ? <Icon size={40} icon="Piggy-bank" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 4 ? <Icon size={40} icon="Junior-account" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 5 ? <Icon size={40} icon="Branches" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 6 ? <Icon size={40} icon="Investment" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              {type === 7 ? <Icon size={40} icon="Premium-acc" className={[b.mr2, "icon-blog"].join(" ")} /> : ''}
                              <p className={['firm-box-text-sm', b.dFlex, b.alignItemsCenter, b.justifyContentCenter].join(' ')}>{name}</p>
                            </div>
                          </Link>
                          <Out item={account} withoutText />
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            </div>

            {allMysqlCard.edges.length && (<>
              <h2><Translate tKey="Similar products" /></h2>
              <SimilarProducts items={allMysqlCard} product="cards" productType='cards' />
            </>)}
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Card;

export const query = graphql`
  query QueryCards($mysqlId: Int!, $promoCluster : String!) {

    allMysqlDescriptionCard(filter: { card_id: { eq: $mysqlId }}) {
      nodes {
        description,
        header
        name
      }
    }

    mysqlCard(mysqlId: { eq: $mysqlId }) {
      advantages_description
      description_advantages
      disadvantages_description
      atms_cost
      atms_cost_info
      free_period
      free_period_info
      card_fee
      card_fee_info
      card_operator
      interest
      name
      rating
      prefix_url
      specific_url
      mysqlId
      url
      wniosker_id
      inactive_reason
      bank {
        name
        prefix_url
        specific_url
        url
        accounts {
          url
          prefix_url
          specific_url
          name
          type
          wniosker_id
        }
        credits {
          url
          name
          prefix_url
          specific_url
          type
          wniosker_id
        }
      }
      mysqlImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    faq: allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "card" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }

    allMysqlComments(filter: {type: {eq: "card"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlSeo(filter: { item_id: { eq: $mysqlId }, item_type: { eq: "card" }}) {
      nodes {
        ...SeoData
      }
    }

    mysqlRankingProductPositions(type: {eq: "cards"}, item_id: {eq: $mysqlId}) {
      position
    }

    allMysqlBlog(
      filter: {promo_bonus: {ne: ""}, is_promo_end: { ne: 1 }, cluster: {eq: $promoCluster}}, limit: 3) {
      nodes {
        description
        term_normalized
        terms_path
        url
        image_description
        title
        mysqlImages {
          childImageSharp {
            gatsbyImageData(width: 70)
          }
        }
      }
    }

    allMysqlCard(limit: 4, filter: {mysqlId: {ne: $mysqlId}, form_visible: {eq: 1}}) {
      edges {
        node {
          mysqlId
          name
          user_comments
          rating
          url
          specific_url
          prefix_url
          type
          bank {
            prefix_url
            specific_url
            url
          }
          mysqlImage {
            childImageSharp {
              gatsbyImageData(width: 103)
            }
          }
        }
      }
    }
  }
`;
